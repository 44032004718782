import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import profile from "../../images/blank-profile-picture.webp";

import moment from "moment";
function PharmacyDetails(props) {
  const [modal, setModal] = useState(false);
  const userData = props.location.state;
  console.log(userData, "daatt");
  return (
    <div>
      <div className="page-titles">
        <h4>Pharmacy Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="pharmacy-management">Pharmacy Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Pharmacy Details</Link>
          </li>
        </ol>
      </div>
      <Card style={{ width: "70%" }}>
        <Card.Header className="d-block">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
           
            <div className="text-left">
              <h3 className="text-black ">
                {userData?.pharmacyName}
              </h3>
              <p className="text-black mb-0">{userData?.email} </p>
              <p className="text-black mb-0">
                {userData?.phoneNumber
                  ? "+" + userData?.countryCode + " " + userData?.phoneNumber
                  : ""}{" "}
              </p>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
          <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Address</h5>
              <p className="">{userData?.address ? userData?.address : "--"}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Rating</h5>
              <p className="">
                {userData?.rating
                  }
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Opening Hours</h5>
              <p className="">
              {userData?.openingHours &&
                      moment(userData?.openingHours?.startTime, "HH:mm:ss").format("hh:mm A")}
                    -{moment(userData?.openingHours?.closeTime, "HH:mm:ss").format("hh:mm A")}
              </p>
            </div>
            
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PharmacyDetails;
