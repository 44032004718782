import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import profile from "../../images/blank-profile-picture.webp";

function DoctorDetails(props) {
  const [modal, setModal] = useState(false);
  const userData = props.location.state;
  console.log(userData, "daatt");
  return (
    <div>
      <div className="page-titles">
        <h4>Doctor Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="doctors-management">Doctors Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Doctor Details</Link>
          </li>
        </ol>
      </div>
      <Card style={{ width: "70%" }}>
        <Card.Header className="d-block">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
            <div>
              <img
                src={userData?.image?userData?.image:profile}
                width={100}
                height={100}
                className="text-center"
              />
            </div>
            <div className="text-left">
              <h3 className="text-black ">{userData?.name} </h3>
              <p className="text-black mb-0">{userData?.email} </p>
              <p className="text-black mb-0">
                {userData?.phoneNumber
                  ? "+" + userData?.countryCode + " " + userData?.phoneNumber
                  : ""}{" "}
              </p>
              <p className="text-black mb-0">{userData?.description} </p>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Address</h5>
              <p className="">{userData?.address ? userData?.address : "--"}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Qualification</h5>
              <p className="">
                {userData?.qualification ? userData?.qualification : "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Experience</h5>
              <p className="">{userData?.experience} Years</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Area Of Expertise</h5>
              <p className="">
                {userData?.areaOfExpertise ? userData?.areaOfExpertise : "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Specialty</h5>
              <p className="">
                {userData?.specialty ? userData.specialty.join(", ") : "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Hospital</h5>
              <p className="">
                {userData?.hospital ? userData.hospital: "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Patient Treated</h5>
              <p className="">
                {userData?.patientTreated ? userData.patientTreated: "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Documnets</h5>
              <p className="">
                {userData?.files?.map((item,i)=>(
                  <img src={item?.file} key={i} width={100} height={100}/>
                ))}
                
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default DoctorDetails;
