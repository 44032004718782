import React, { useEffect, useRef, useState } from "react";
import { Badge, Modal, Table } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import SwitchBotton from "../components/ReactSwitch";
import { addAvailability } from "../../services/User/UserService";
const AddSchedule = ({ show, table, prevData, onHide }) => {
  console.log(prevData.weeklySchedule, "old data");
  let errorsObj = {};
  const [errors, setErrors] = useState(errorsObj);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [formData, setFormData] = useState({
    doctorId: "",
    weeklySchedule: days.map((day) => ({
      day: day,
      startTime: "",
      endTime: "",
      status: false,
    })),
  });

  console.log(formData, "formdata");
  const handleInputChangeTime = (e, index, field) => {
    const { value } = e.target;
    const newWeeklySchedule = [...formData.weeklySchedule];

    if (field === "startTime") {
      // Update the start time
      newWeeklySchedule[index].startTime = value;
      // If end time is less than start time, update end time to be equal to start time
      if (
        new Date(`2000-01-01 ${newWeeklySchedule[index].endTime}`) <
        new Date(`2000-01-01 ${value}`)
      ) {
        newWeeklySchedule[index].endTime = value;
      }
    } else if (field === "endTime") {
      // Update the end time
      newWeeklySchedule[index].endTime = value;
      // If end time is less than start time, update start time to be equal to end time
      if (
        new Date(`2000-01-01 ${value}`) <
        new Date(`2000-01-01 ${newWeeklySchedule[index].startTime}`)
      ) {
        newWeeklySchedule[index].startTime = value;
      }
    } else if (field === "status") {
      // Update status
      newWeeklySchedule[index].status = !newWeeklySchedule[index].status; // Here's the inversion
    }
    setFormData({ ...formData, weeklySchedule: newWeeklySchedule });
  };

  //// function to add data/////
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    setErrors(errorObj);
    if (error) {
      return;
    }

    console.log(formData, "form data");
    try {
      const response = await addAvailability(
        formData,
      );
      console.log(response.data.message, "success");
      notifyTopRight(response?.data?.message);
      table();
      onHide();
    } catch (error) {
      console.log(error, "error");
      notifyError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    setFormData({
      doctorId: prevData?._id,
      weeklySchedule: days.map((day) => {
        // Find the corresponding opening hours for the current day
        const openingHour = prevData?.weeklySchedule.find(
          (opening) => opening.day === day
        );

        // If opening hours exist for the current day, use them, otherwise default to null
        return openingHour
          ? {
              day: openingHour.day,
              startTime: openingHour.startTime,
              endTime: openingHour.endTime,
              status: openingHour.status,
            }
          : {
              day: day,
              startTime: "",
              endTime: "",
              status: false,
            };
      }),
    });
  }, []);

  return (
    <Modal
      className="modal fade"
      size="lg"
      show={show}
      onHide={onHide}
      centered
    >
      <div className="" role="document">
        <form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Weekly Schedule</h4>
            {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">
                    Weekly Schedule
                  </label>
                  <div className="contact-name">
                    <Table>
                      <thead>
                        <tr>
                          <td>
                            <strong>Day</strong>
                          </td>

                          <td>
                            <strong>Start</strong>
                          </td>

                          <td>
                            <strong>End</strong>
                          </td>
                          <td>
                            <strong>Status</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {formData?.weeklySchedule?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{ borderTop: "none" }}
                              className="border-bottom"
                            >
                              {item.day}
                            </td>
                            <td
                              style={{ borderTop: "none" }}
                              className="border-bottom"
                            >
                              <input
                                type="time"
                                className="form-control"
                                name={`startTime-${index}`}
                                value={item.startTime}
                                onChange={(e) =>
                                  handleInputChangeTime(e, index, "startTime")
                                }
                                list="timeOptions"
                              />
                              <datalist id="timeOptions">
                                {Array.from({ length: 24 * 4 }, (_, i) => {
                                  const hours = Math.floor(i / 4)
                                    .toString()
                                    .padStart(2, "0");
                                  const minutes = ((i % 4) * 15)
                                    .toString()
                                    .padStart(2, "0");
                                  return (
                                    <option
                                      key={i}
                                      value={`${hours}:${minutes}`}
                                    />
                                  );
                                })}
                              </datalist>
                            </td>

                            <td
                              style={{ borderTop: "none" }}
                              className="border-bottom"
                            >
                              <input
                                type="time"
                                className="form-control"
                                name={`endTime-${index}`}
                                value={item.endTime}
                                onChange={(e) =>
                                  handleInputChangeTime(e, index, "endTime")
                                }
                                list="timeOptions"
                              />
                              <datalist id="timeOptions">
                                {Array.from({ length: 24 * 4 }, (_, i) => {
                                  const hours = Math.floor(i / 4)
                                    .toString()
                                    .padStart(2, "0");
                                  const minutes = ((i % 4) * 15)
                                    .toString()
                                    .padStart(2, "0");
                                  return (
                                    <option
                                      key={i}
                                      value={`${hours}:${minutes}`}
                                    />
                                  );
                                })}
                              </datalist>
                            </td>
                            <td
                              style={{ borderTop: "none" }}
                              className="border-bottom"
                            >
                              <SwitchBotton
                                name="status"
                                value={item.status}
                                setValue={(value) =>
                                  handleInputChangeTime(
                                    { target: { value: value } },
                                    index,
                                    "status"
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/* <span className="validation-text text-danger">
                      {errors.openingStartTime}
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
            <button
              type="button"
              onClick={() => onHide()}
              className="btn btn-secondary"
            >
              <i className="flaticon-delete-1"></i> Discard
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default AddSchedule;
