import React, { useState } from "react";
import Switch from "react-switch";

export default function SwitchBotton({ value, setValue }) {
  const handleChange = (nextChecked) => {
    setValue(nextChecked);
  };

  return (
    <div className="example">
      <label>
        <Switch
          onChange={handleChange}
          checked={value}
          className="react-switch"
        />
      </label>
    </div>
  );
}
