import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import profile from "../../images/blank-profile-picture.webp";

import moment from "moment";
function UserDetails(props) {
  const [modal, setModal] = useState(false);
  const userData = props.location.state;
  console.log(userData, "daatt");
  return (
    <div>
      <div className="page-titles">
        <h4>Patient Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="patient-management">Patient Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Patient Details</Link>
          </li>
        </ol>
      </div>
      <Card style={{ width: "70%" }}>
        <Card.Header className="d-block">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
            <div>
              <img
                src={userData?.image ? userData.image : profile}
                width={100}
                height={100}
                className="text-center"
              />
            </div>
            <div className="text-left">
              <h3 className="text-black ">
                {userData?.name + `(${userData?.gender})`}{" "}
              </h3>
              <p className="text-black mb-0">{userData?.email} </p>
              <p className="text-black mb-0">
                {userData?.phoneNumber
                  ? "+" + userData?.countryCode + " " + userData?.phoneNumber
                  : ""}{" "}
              </p>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Date of Birth</h5>
              <p className="">
                {userData?.dateOfBirth
                  ? moment(userData?.dateOfBirth).format("DD/MM/YYYY")
                  : "--"}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5>Address</h5>
              <p className="">{userData?.address ? userData?.address : "--"}</p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default UserDetails;
