/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import dbIcon from "../../../images/svg/Group 1171274941.svg";
import dbBlue from "../../../images/svg/Group 1171274941 (1).svg";
import patientIcon from "../../../images/svg/Crowd Patient.svg";
import patientBlue from "../../../images/svg/Crowd Patient (1).svg";
import docIcon from "../../../images/svg/Vector.svg";
import docBlue from "../../../images/svg/Vector (1).svg";
import pharmacyIcon from "../../../images/svg/drug-1.svg";
import pharmacyBlue from "../../../images/svg/drug-1 - Copy.svg";
import labIcon from "../../../images/svg/management-service-1.svg";
import labBlue from "../../../images/svg/management-service-1 - Copy.svg";
import appoiIcon from "../../../images/svg/Icon.svg";
import appoiBlue from "../../../images/svg/Icon (1).svg";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      users = ["patients-management"],
      lab = ["lab-management"],
      pharmacy = ["pharmacy-management"],
      appointment = ["appointment-requests"],
      doc = ["doctors-management"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
                {/* <i className="flaticon-381-networking"></i> */}
                <img
                  src={dashBoard.includes(path) ? dbBlue : dbIcon}
                  width={20}
                  className="mr-3"
                />
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link to="patients-management" className="ai-icon">
                {/* <i className="flaticon-381-user-9"></i> */}
                <img
                  src={users.includes(path) ? patientBlue : patientIcon}
                  width={20}
                  className="mr-3"
                />
                <span className="nav-text">Patient Management</span>
              </Link>
            </li>
            <li className={`${doc.includes(path) ? "mm-active" : ""}`}>
              <Link to="doctors-management" className="ai-icon">
                {/* <i className="flaticon-381-user-9"></i> */}
                <img
                  src={doc.includes(path) ? docBlue : docIcon}
                  width={20}
                  className="mr-3"
                />
                <span className="nav-text">Doctor Management</span>
              </Link>
            </li>
            <li className={`${pharmacy.includes(path) ? "mm-active" : ""}`}>
              <Link to="pharmacy-management" className="ai-icon">
                {/* <i className="flaticon-381-user-9"></i> */}
                <img
                  src={pharmacy.includes(path) ? pharmacyBlue : pharmacyIcon}
                  width={20}
                  className="mr-3"
                />
                <span className="nav-text">Pharmacy Management</span>
              </Link>
            </li>
            <li className={`${lab.includes(path) ? "mm-active" : ""}`}>
              <Link to="lab-management" className="ai-icon">
                {/* <i className="flaticon-381-user-9"></i> */}
                <img
                  src={lab.includes(path) ? labBlue : labIcon}
                  width={20}
                  className="mr-3"
                />
                <span className="nav-text">Lab Management</span>
              </Link>
            </li>
            <li className={`${appointment.includes(path) ? "mm-active" : ""}`}>
              <Link to="appointment-requests" className="ai-icon">
                {/* <i className="flaticon-381-user-9"></i> */}
                <img
                  src={appointment.includes(path) ? appoiBlue : appoiIcon}
                  width={20}
                  className="mr-3"
                />
                <span className="nav-text">Appointments</span>
              </Link>
            </li>
            {/* <li className={`${artical.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                {/* <i className="flaticon-381-television"></i> *
                <img src={articalIcon} ></img>
                <span className="nav-text pl-4">CMS</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "article-management" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/article-management"
                  >
                    Article Management
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "advertise-management" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/advertise-management"
                  >
                    Advertise Management
                  </Link>
                </li>
              </ul>
            </li>
          
            */}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
