import axiosInstance from "../AxiosInstance";
import { APPROVE_REQUESTS, AVAILABILITY, BLOCK_USERS, GET_APPOINTMENT_REQUESTS, GET_REQUESTS, GET_USERS } from "./UserApiEndPoints";
export function getAllUsers(currentPage, limit, search, type) {
  return axiosInstance.get(
    GET_USERS +
      `?page=${currentPage}&limit=${limit}&search=${search}&type=${type}`
  );
}

export function getRequests(currentPage, limit, type) {
  return axiosInstance.get(
    GET_REQUESTS + `?page=${currentPage}&limit=${limit}&type=${type}`
  );
}

export function blockUser(id, actionType) {
  const postData = { userId: id };
  return axiosInstance.put(BLOCK_USERS, postData);
}

export function approveDoc(id,status) {
  const postData = {
    Id:id,
    status:status
  }
  return axiosInstance.put(APPROVE_REQUESTS, postData);
}
export function getAppointmentRequests(currentPage, limit, type) {
  return axiosInstance.get(
    GET_APPOINTMENT_REQUESTS + `?page=${currentPage}&limit=${limit}&type=${type}`
  );
}
export function addAvailability(formData) {
  // const postData = {
  //   Id:id,
  //   status:status
  // }
  return axiosInstance.put(AVAILABILITY, formData);
}
